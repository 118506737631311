import { AgGridReact, GridOptions, IServerSideDatasource } from '@uturn/ui-kit';
import { useMemo } from 'react';
import {
	OrganizationUser,
	getOrganizationsIdUsers,
} from '@uturn/api/backoffice/v1';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 50;

const Users = ({ organizationId }: { organizationId: number }) => {
	const navigate = useNavigate();

	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: users } = await getOrganizationsIdUsers(
						organizationId,
						{
							page: page + 1,
							size: pageSize,
							sort: JSON.stringify(
								request.sortModel?.length > 0
									? request.sortModel.map(({ colId, sort }) => [colId, sort])
									: [['id', 'DESC']],
							),
						},
					);

					if (users.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					success({
						rowData: users.data,
						rowCount: users.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const gridOptions: GridOptions<OrganizationUser> = {
		defaultColDef: {
			flex: 1,
			suppressFloatingFilterButton: true,
			suppressHeaderFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			sortable: true,
			floatingFilter: false,
		},
		columnDefs: [
			{
				field: 'id',
				headerName: 'ID',
				filter: '',
				cellDataType: 'number',
				flex: 0,
			},
			{
				field: 'firstName',
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'lastName',
				flex: 0,
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'username',
				headerName: 'E-mail',
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'phone',
				sortable: false,
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'role',
				flex: 0,
				sortable: false,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: [
						'C_ADMIN',
						'C_USER',
						'C_DRIVER',
						'S_ADMIN',
						'S_USER',
						'G_SHIPPER',
						'G_CARRIER',
					],
				},
			},
		],
		rowModelType: 'serverSide',
		serverSideDatasource,
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		cacheBlockSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
		onRowClicked: (event) => navigate(`/users/${event.data?.id}`),
	};

	return (
		<AgGridReact
			{...gridOptions}
			className="ag-theme-quartz align-baseline z-0"
		/>
	);
};

export default Users;
