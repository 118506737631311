import {
	faArrowUpToLine,
	faArrowDownToLine,
	faArrowsRotate,
	faBan,
} from '@fortawesome/pro-regular-svg-icons';
import { GetFees200DataItemFeesItemUnit } from '@uturn/api/finance/v1';
import {
	Button,
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
	Checkbox,
	CustomCellEditorProps,
	CustomCellRendererProps,
	Form,
	FormItem,
	FormControl,
	FormField,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	RadioGroup,
	InputAddon,
} from '@uturn/ui-kit';
import { FC, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { valueFormatterFee, getFeeUnit } from './utils';
import { feeTransportTypeLabels, feeTypeLabels, feeUnitLabels } from './labels';
import { formSchema, FormValues } from './schema';
import { FeeData, RowData } from './types';
import {
	FormOptionsType,
	FormRadioGroupHorizontalItem,
} from '../../../../../../../components/radio/radio';
import { Action, Subject, useAbility } from '../../../../../../../abac';

/** Ready
 */
export const CellRendererFee: FC<CustomCellRendererProps<RowData, FeeData>> = (
	props,
) => {
	const { data, getValue } = props;

	if (!getValue) {
		return null;
	}

	if (!data) {
		return null;
	}

	const currencySign = '€';

	const fee = getValue();

	if (!fee || fee.index === -1) {
		return null;
	}

	return (
		<div className={twMerge('flex items-center justify-between')}>
			<span className={twMerge('pr-2')}>{valueFormatterFee(fee)}</span>
			<span className="text-muted-foreground space-x-1.5">
				{/* {import.meta.env.MODE === 'development' && `id:${fee.id} `} */}
				{fee.feeMin !== undefined &&
					fee.feeMin > 0 &&
					fee.unit === GetFees200DataItemFeesItemUnit.PERCENTAGE && (
						<span>
							{currencySign}
							{fee.feeMin.toFixed(0)}
							<Icon icon={faArrowDownToLine} />
						</span>
					)}
				{fee.feeMax !== undefined &&
					fee.feeMax > 0 &&
					fee.unit === GetFees200DataItemFeesItemUnit.PERCENTAGE && (
						<span>
							{currencySign}
							{fee.feeMax.toFixed(0)}
							<Icon icon={faArrowUpToLine} />
						</span>
					)}
				<Icon icon={fee.autoUpdate ? faArrowsRotate : faBan} />
			</span>
		</div>
	);
};

/** Ready
 */
export const CellEditorFee: FC<
	CustomCellEditorProps<RowData, FeeData> & { setIsDirty: () => void }
> = (props) => {
	const {
		onValueChange,
		value: currentValue,
		stopEditing,
		setIsDirty,
		// node,
		// column,
		// data,
		// colDef,
		// initialValue,
		// eventKey, // 'Enter', 'F2'
		// rowIndex,
		// cellStartedEdit,
		// onKeyDown,
		// eGridCell,
		// parseValue,
		// formatValue,
		// api,
		// context,
	} = props;

	const ability = useAbility();

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: { ...currentValue },
	});

	useEffect(() => {
		form.setFocus('fee', { shouldSelect: true });
	}, [form, form.setFocus]);

	if (!currentValue) {
		return null;
	}

	const currencySign = '€';

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		const finalValue: FeeData = {
			...formData,
			isDirty: true,
		};

		onValueChange(finalValue);

		setIsDirty();

		form.reset();
		stopEditing();
	};

	const onError: SubmitErrorHandler<FormValues> = (formData) => {
		console.error('CellEditorFee.onError:', formData);
	};

	const feeUnitOptions: FormOptionsType<GetFees200DataItemFeesItemUnit>[] = [
		{
			id: GetFees200DataItemFeesItemUnit.FLAT,
			value: GetFees200DataItemFeesItemUnit.FLAT,
			label: feeUnitLabels[GetFees200DataItemFeesItemUnit.FLAT],
		},
		{
			id: GetFees200DataItemFeesItemUnit.PERCENTAGE,
			value: GetFees200DataItemFeesItemUnit.PERCENTAGE,
			label: feeUnitLabels[GetFees200DataItemFeesItemUnit.PERCENTAGE],
		},
	];

	if (ability.cannot(Action.Update, Subject.PlatformConfiguration)) {
		return (
			<Card className="bg-red-100 text-red-500">
				<CardHeader>
					<CardTitle className="text-md">Access denied</CardTitle>
				</CardHeader>
				<CardContent>
					You do not have permission to{' '}
					{currentValue.index === -1 ? 'create' : 'update'} fees.
				</CardContent>
				<CardFooter className="flex justify-end">
					<Button variant="destructive" onClick={() => stopEditing()}>
						Close
					</Button>
				</CardFooter>
			</Card>
		);
	}

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit, onError)}
				onLoad={() => form.setFocus('fee')}
			>
				<Card className="w-[350px]">
					<CardHeader>
						<CardTitle>Fee details</CardTitle>
						<CardDescription>
							Change default fee configuration for{' '}
							<i>{feeTypeLabels[currentValue.type]}</i>{' '}
							<i>{feeTransportTypeLabels[currentValue.transportType]}</i> here.
						</CardDescription>
					</CardHeader>
					<CardContent>
						<div className="grid w-full items-center gap-4">
							<div className="flex flex-col space-y-1.5">
								<FormField
									control={form.control}
									name="unit"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="required-asterix">Type</FormLabel>
											<FormControl>
												<RadioGroup
													value={field.value}
													onValueChange={(event) => {
														field.onChange(event);
														form.setFocus('fee', { shouldSelect: true });
													}}
													className="grid grid-cols-2 gap-2 mt-0 pt-0"
												>
													{feeUnitOptions.map((item) => {
														return (
															<FormRadioGroupHorizontalItem
																key={item.id}
																{...item}
															/>
														);
													})}
												</RadioGroup>
											</FormControl>
											<FormMessage className="text-center" />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-col space-y-1.5">
								<FormField
									control={form.control}
									name="fee"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="required-asterix">Amount</FormLabel>
											<div className="relative">
												<FormControl>
													<Input
														{...field}
														// type="number"
														className={
															form.getValues('unit') ===
															GetFees200DataItemFeesItemUnit.PERCENTAGE
																? 'pr-8'
																: 'pl-8'
														}
														placeholder="Amount of your fee"
													/>
												</FormControl>
												<InputAddon
													type={
														form.getValues('unit') ===
														GetFees200DataItemFeesItemUnit.PERCENTAGE
															? 'trailing'
															: 'leading'
													}
												>
													<span
														className={twMerge(
															'text-secondary-500',
															'sm:text-sm',
														)}
													>
														{getFeeUnit(form.getValues('unit'))}
													</span>
												</InputAddon>
											</div>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							{form.getValues('unit') ===
								GetFees200DataItemFeesItemUnit.PERCENTAGE && (
								<div className="grid grid-cols-6 gap-5">
									<div className="col-span-3 space-y-1.5">
										<FormField
											control={form.control}
											name="feeMin"
											render={({ field }) => (
												<FormItem>
													<FormLabel>Lower bound ({currencySign})</FormLabel>
													<FormControl>
														<Input {...field} placeholder="Minimum fee" />
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-3 space-y-1.5">
										<FormField
											control={form.control}
											name="feeMax"
											render={({ field }) => (
												<FormItem>
													<FormLabel>Upper bound ({currencySign})</FormLabel>
													<FormControl>
														<Input {...field} placeholder="Maximum fee" />
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
								</div>
							)}
							<div className="flex flex-col space-y-1.5">
								<FormField
									control={form.control}
									name="autoUpdate"
									render={({ field }) => (
										<FormItem className="flex flex-row items-start space-x-3 space-y-0">
											<FormControl>
												<Checkbox
													checked={field.value}
													onCheckedChange={field.onChange}
												/>
											</FormControl>
											<FormLabel className="required-asterix">
												Auto update{' '}
												<span className="text-xs text-muted-foreground">
													(when default fees change)
												</span>
											</FormLabel>
										</FormItem>
									)}
								/>
							</div>
						</div>
					</CardContent>
					<CardFooter className="flex justify-between">
						<span className="space-x-1.5">
							<Button variant="outline" onClick={() => stopEditing()}>
								Cancel
							</Button>
						</span>
						<Button type="submit">Update</Button>
					</CardFooter>
				</Card>
			</form>
		</Form>
	);
};
