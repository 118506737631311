import { Helmet } from 'react-helmet-async';
import Table from './table';
import Page from '../../../../../layout/page';
import {
	AgGridReact,
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Icon,
	sonner,
} from '@uturn/ui-kit';
import { useRef, useState } from 'react';
import Overlay from '../../../../../components/overlay/overlay';
import { RowData } from './components/fee/types';
import { usePutFeesDefaultsId } from '@uturn/api/finance/v1';
import {
	castClipboard,
	castPutFee,
	getErrorMessage,
	getSuccessMessage,
} from './components/fee/utils';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { useBlocker } from 'react-router-dom';
import { Action, Can, Subject, useAbility } from '../../../../../abac';
import ErrorPage from './components/error/error';

const Overview = () => {
	const ref = useRef<AgGridReact<RowData>>(null);
	const ability = useAbility();

	const [isDirty, setIsDirty] = useState(false);

	const { mutateAsync, isLoading } = usePutFeesDefaultsId();

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return isDirty && currentLocation.pathname !== nextLocation.pathname;
	});

	const getRowData = () => {
		let rowData: RowData | undefined;

		// Iterate through every row node in the grid,
		// i.e.: The 'DEFAULT_FEES' one only 😊.
		ref.current?.api.forEachNode((rowNode) => {
			if (!rowNode.data) {
				return;
			}
			rowData = rowNode.data;
		});

		return rowData;
	};

	const getUpdateData = () => {
		const data = getRowData();

		if (!data) {
			return undefined;
		}

		// Start with visible fees.
		const value = [
			castPutFee(data.market_shunt),
			castPutFee(data.market_import),
			castPutFee(data.contract_shunt),
			castPutFee(data.contract_import),
		];

		// Finish with hidden fees.
		const marketClipboard = castClipboard(data.market_import);
		value.push(castPutFee({ ...data.market_export, ...marketClipboard }));
		value.push(castPutFee({ ...data.market_other, ...marketClipboard }));
		const contractClipboard = castClipboard(data.contract_import);
		value.push(castPutFee({ ...data.contract_export, ...contractClipboard }));
		value.push(castPutFee({ ...data.contract_other, ...contractClipboard }));

		return { id: data.id, data: value };
	};

	const onUpdate = async () => {
		if (ability.cannot(Action.Update, Subject.PlatformConfiguration)) {
			sonner.error('You do not have permission to update the default fees');
			return;
		}

		if (ref.current === null) {
			sonner.error('Unknown issue occured while trying to submit the form');
			return;
		}

		const variables = getUpdateData();

		if (!variables) {
			sonner.error('Could not get data to submit from the grid');
			return;
		}

		await mutateAsync(variables, {
			onSuccess: () => {
				ref.current?.api.refreshServerSide();
				setIsDirty(false);
				sonner.success(getSuccessMessage());
			},
			onError: (error) => {
				console.error(error);
				sonner.success(getErrorMessage(error));
			},
		});
	};

	return (
		<>
			<Helmet title="Default fees" />
			{isLoading && <Overlay>Loading...</Overlay>}
			<Page
				title="Default fees"
				// breadcrumbs={true}
				backButton={{
					label: 'Fees',
					href: '..',
				}}
				primaryActions={[
					{
						variant: 'default',
						label: (
							<>
								{isLoading ? (
									<>
										<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
										Applying...
									</>
								) : (
									'Apply changes'
								)}
							</>
						),
						disabled: !isDirty || isLoading,
						onClick: onUpdate,
					},
				]}
			>
				<Can I={Action.Read} a={Subject.PlatformConfiguration} passThrough>
					{(allowed) =>
						allowed ? (
							<>
								<div className="flex-1">
									<Table gridRef={ref} setIsDirty={() => setIsDirty(true)} />
								</div>
								{blocker.state === 'blocked' && (
									<AlertDialog open={true}>
										<AlertDialogContent>
											<AlertDialogHeader>
												<AlertDialogTitle>
													Are you sure you want to leave this page?
												</AlertDialogTitle>
												<AlertDialogDescription>
													You have unsaved changes. Are you sure you want to
													leave this page? Any changes you have made will be
													lost.
												</AlertDialogDescription>
											</AlertDialogHeader>
											<AlertDialogFooter>
												<AlertDialogCancel onClick={() => blocker.reset()}>
													Stay
												</AlertDialogCancel>
												<AlertDialogAction onClick={() => blocker.proceed()}>
													Leave
												</AlertDialogAction>
											</AlertDialogFooter>
										</AlertDialogContent>
									</AlertDialog>
								)}
							</>
						) : (
							<ErrorPage>
								Access denied: You do not have access to view this content.
							</ErrorPage>
						)
					}
				</Can>
			</Page>
		</>
	);
};

export default Overview;
