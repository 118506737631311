import { ReactNode, useEffect, useState } from 'react';
import { AbilityContext } from './context';
import {
	AppAbility,
	defineRulesFor,
	type UserAccount,
} from '@uturn/fastify-auth-internal/auth';

const EXPIRATION_INTERVAL = 1000 * 60 * 15; // 15m

type AbilityProviderProps = {
	children: ReactNode;
	ability: AppAbility;
	user: UserAccount;
};

export const AbilityProvider = (props: AbilityProviderProps) => {
	const { children, ability, user } = props;

	const [isExpired, setIsExpired] = useState(false);

	useEffect(() => {
		if (user.id === '' || user.orgSlug === '') {
			return;
		}

		const fetchRules = async () => {
			const rules = defineRulesFor(user);
			ability.update(rules);
			setIsExpired(false);
		};

		fetchRules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id, user.orgSlug, isExpired]);

	useEffect(() => {
		const timer = isExpired
			? undefined
			: setTimeout(() => {
					setIsExpired(true);
				}, EXPIRATION_INTERVAL);

		return () => clearTimeout(timer);
	}, [isExpired]);

	return (
		<AbilityContext.Provider value={ability}>
			{children}
		</AbilityContext.Provider>
	);
};
