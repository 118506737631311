import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '@uturn/ui-kit';
import { PriceCalculatorSubscription } from '@uturn/api/backoffice/v1';
import { CurrencyUtils, DatesUtils } from '@uturn/utilities/browser';

const PriceCalculator = ({
	subscription,
}: {
	subscription: PriceCalculatorSubscription;
}) => {
	// @todo check for permission
	return (
		<Card>
			<CardHeader>
				<CardTitle>Price Calculator</CardTitle>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Tier</TableHead>
							<TableHead>Price</TableHead>
							<TableHead>Start date</TableHead>
							<TableHead>End date</TableHead>
							<TableHead>Auto renew</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						<TableRow>
							<TableCell>{subscription.tier}</TableCell>
							<TableCell>
								{CurrencyUtils.formatPriceString(
									subscription.price.amount ?? 0,
									subscription.price.currency ?? 'EUR',
									'nl-NL',
								)}
							</TableCell>
							<TableCell>
								{DatesUtils.dateStringToFormattedDate(
									subscription.startDate ?? '',
									{ dateFormat: 'dd/MM/yyyy' },
								)}
							</TableCell>
							<TableCell>
								{subscription.endDate
									? DatesUtils.dateStringToFormattedDate(subscription.endDate, {
											dateFormat: 'dd/MM/yyyy',
										})
									: ''}
							</TableCell>
							<TableCell>{subscription.autoRenew ? 'Yes' : 'No'}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default PriceCalculator;
