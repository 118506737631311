import { Helmet } from 'react-helmet-async';
import Table from './table';
import Page from '../../../../../layout/page';
import { AgGridReact, Icon } from '@uturn/ui-kit';
import { useRef, useState } from 'react';
import Overlay from '../../../../../components/overlay/overlay';
import { RowData } from './components/fee/types';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import ErrorPage from './components/error/error';
import { Action, Can, Subject, useAbility } from '../../../../../abac';

const Overview = () => {
	const navigate = useNavigate();
	const ref = useRef<AgGridReact<RowData>>(null);

	const ability = useAbility();

	const [isLoading] = useState(false);

	return (
		<>
			<Helmet title="Fees" />
			{isLoading && <Overlay>Loading...</Overlay>}
			<Page
				title="Fees"
				primaryActions={[
					{
						label: (
							<>
								<Icon icon={faGear} />
								Change defaults
							</>
						),
						disabled: ability.cannot(
							Action.Read,
							Subject.PlatformConfiguration,
						),
						onClick: () => navigate('/fees/defaults'),
					},
				]}
			>
				<Can I={Action.Read} a={Subject.Fee} passThrough>
					{(allowed) =>
						allowed ? (
							<div className="flex-1">
								<Table gridRef={ref} />
							</div>
						) : (
							<ErrorPage>
								Access denied: You do not have access to view this content.
							</ErrorPage>
						)
					}
				</Can>
			</Page>
		</>
	);
};

export default Overview;
