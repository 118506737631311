import { useGetOrganizationsIdGeneralInfo } from '@uturn/api/backoffice/v1';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import Page from '../../../layout/page.tsx';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@uturn/ui-kit';
import Details from './details.tsx';
import Users from './users.tsx';
import Products from './products.tsx';
import Documents from './documents.tsx';
import BillingInfo from './billing-info.tsx';
import { useSearchParams } from 'react-router-dom';
// import ActivityLog from '../../../components/organizations/activity-log.tsx';

const Detail: FC<{ id: number }> = ({ id }) => {
	const { data: organization } = useGetOrganizationsIdGeneralInfo(id, {
		query: {
			suspense: true,
			select: (data) => data.data.data,
		},
	});
	// const [activityTabOpen, setActivityTabOpen] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const activeTab = searchParams.get('tab') ?? 'details';

	const tabChanged = (value: string) => {
		setSearchParams((searchParams) => {
			searchParams.set('tab', value);
			return searchParams;
		});
	};

	// const primaryAction = {
	// 	label: 'Activity log',
	// 	onClick: () => setActivityTabOpen(true),
	// };

	return (
		<>
			<Helmet title="Organizations" />
			<Page title={`${organization?.name} (${organization?.orgNumber})`}>
				{organization ? (
					<Tabs
						defaultValue={activeTab}
						className="h-full"
						onValueChange={tabChanged}
					>
						<TabsList>
							<TabsTrigger value="details">Details</TabsTrigger>
							<TabsTrigger value="users">Users</TabsTrigger>
							<TabsTrigger value="products">Products</TabsTrigger>
							<TabsTrigger value="billing">Billing</TabsTrigger>
							<TabsTrigger value="documents">Documents</TabsTrigger>
						</TabsList>
						<TabsContent value="details">
							<Details
								organizationId={organization.id}
								organizationType={organization?.type}
							/>
						</TabsContent>
						<TabsContent value="users" className="h-2/3">
							<Users organizationId={id} />
						</TabsContent>
						<TabsContent value="products">
							<Products
								organizationId={organization.id}
								organizationType={organization.type}
							/>
						</TabsContent>
						<TabsContent value="billing">
							<BillingInfo organizationId={organization.id} />
						</TabsContent>
						<TabsContent value="documents">
							<Documents organizationId={organization.id} />
						</TabsContent>
					</Tabs>
				) : (
					<div>Failed to retrieve the organization!</div>
				)}
			</Page>
			{/*<ActivityLog open={activityTabOpen} onStateChange={setActivityTabOpen} />*/}
		</>
	);
};

export default Detail;
