export const dateFilterGetter = (filter: {
    dateFrom: string | null;
    dateTo: string | null;
    filterType: 'date';
    type: 'equals' | 'before' | 'after' | 'inRange';
}) => {
    const { type } = filter;
    let { dateFrom, dateTo } = filter;

    if (!dateFrom) {
        return undefined;
    }

    dateFrom = dateFrom.replace(' 00:00:00', '');

    if (type === 'inRange') {
        if (!dateTo) {
            return dateFrom;
        }
        dateTo = dateTo.replace(' 00:00:00', '');
        return `${dateFrom}...${dateTo}`
    }

    if (type === 'before') {
        return `<${dateFrom}`
    }

    if (type === 'after') {
        return `>${dateFrom}`
    }

    // type === 'equals'
    return dateFrom;
}