import {
	getOrganizations,
	OrganizationSummary,
} from '@uturn/api/backoffice/v1';
import { AgGridReact, GridOptions, IServerSideDatasource } from '@uturn/ui-kit';
import { GenericUtils } from '@uturn/utilities/browser';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 50;

const CapitalizeRenderer = (params: { value: string }) => {
	let value = params.value.toUpperCase();
	if (value === 'SHI') value = 'SHIPPER';
	if (value === 'CAR') value = 'CARRIER';
	return GenericUtils.capitalizeFirstLetter(value);
};

const Table = () => {
	const navigate = useNavigate();

	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: organizations } = await getOrganizations({
						page: page + 1,
						size: pageSize,
						sort: JSON.stringify(
							request.sortModel?.length > 0
								? request.sortModel.map(({ colId, sort }) => [colId, sort])
								: [['orgNumber', 'DESC']],
						),
						name:
							request.filterModel && 'name' in request.filterModel
								? request.filterModel.name.filter
								: undefined,
						orgNumber:
							request.filterModel && 'orgNumber' in request.filterModel
								? request.filterModel.orgNumber.filter
								: undefined,
						status:
							request.filterModel && 'status' in request.filterModel
								? request.filterModel.status.values
								: undefined,
						organizationType:
							request.filterModel && 'type' in request.filterModel
								? request.filterModel.type.values
								: undefined,
						city:
							request.filterModel &&
							'contactDetails.address.city' in request.filterModel
								? request.filterModel['contactDetails.address.city'].filter
								: undefined,
					});

					if (organizations.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					success({
						rowData: organizations.data,
						rowCount: organizations.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const gridOptions: GridOptions<OrganizationSummary> = {
		defaultColDef: {
			flex: 1,
			suppressFloatingFilterButton: true,
			suppressHeaderFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			sortable: true,
		},
		columnDefs: [
			{
				field: 'orgNumber',
				headerName: 'Organization number',
				filter: 'agNumberColumnFilter',
				cellDataType: 'number',
				flex: 0,
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'status',
				cellRenderer: CapitalizeRenderer,
				suppressFloatingFilterButton: false,
				flex: 0,
				filter: 'agSetColumnFilter',
				floatingFilter: true,
				filterParams: {
					values: ['New', 'Approved', 'Rejected', 'Blocked', 'Deleted'],
				},
			},
			{
				field: 'name',
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'type',
				cellRenderer: CapitalizeRenderer,
				suppressFloatingFilterButton: false,
				flex: 0,
				filter: 'agSetColumnFilter',
				floatingFilter: true,
				filterParams: {
					values: ['Shipper', 'Carrier'],
				},
			},
			{
				field: 'contactDetails.address.city',
				headerName: 'City',
				flex: 0,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
		],
		rowModelType: 'serverSide',
		serverSideDatasource,
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		cacheBlockSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
		onRowClicked: (event) => navigate(`/organizations/${event.data?.id}`),
	};
	return (
		<AgGridReact
			{...gridOptions}
			className="ag-theme-quartz align-baseline z-0"
		/>
	);
};

export default Table;
